export const getState = (state) => {
  switch (state) {
  case 'confirm':
    return 'En espera de confirmacion'
  case 'request':
    return 'En espera de presupuesto'
  case 'estimate':
    return 'En espera de pago'
  case 'pay':
    return 'En espera de revision de pago'
  case 'payRevition':
    return 'En espera de despacho'
  case 'dispatch':
    return 'En espera de liberacion'
  case 'delivery':
    return 'Liberadas'
  case 'finish':
    return 'Finalizadas'
  case 'transport':
    return 'Seleccion de transporte'
  case 'onroute':
    return 'En ruta'
  case 'canceled':
    return 'Cancelado'
  }
  return 'Estatus no valido'
}

export const getOrderState = (state) => {
  switch (state) {
  case 'confirm':
    return 'En espera de confirmacion'
  case 'request':
    return 'En espera de presupuesto'
  case 'estimate':
    return 'En espera de pago'
  case 'pay':
    return 'En espera de revision de pago'
  case 'payRevition':
    return 'En espera de despacho'
  case 'dispatch':
    return 'En espera de liberacion'
  case 'delivery':
    return 'Liberadas'
  case 'finish':
    return 'Finalizadas'
  case 'transport':
    return 'Seleccion de transporte'
  case 'onroute':
    return 'En ruta'
  case 'canceled':
    return 'Cancelado'
  }
  return 'Estatus no valido'
}

export const getRoleName = (role) => {
  if (role === 'admin') {
    return 'Administrador'
  } else if (role === 'storer') {
    return 'Almacenista'
  } else if (role === 'financial') {
    return 'Finanzas'
  } else if (role === 'sales') {
    return 'Vendedor'
  } else if (role === 'supersales') {
    return 'Supervisor de ventas'
  } else if (role === 'transport') {
    return 'Transportista'
  } else if (role === 'dealer') {
    return 'Distribuidor'
  } else if (role === 'endline') {
    return 'Linea final'
  }
}
