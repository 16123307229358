<template>
  <div>
    <Head title="Reports" />
    <h1 class="mb-8 text-3xl font-bold">Reportes</h1>

    <div class="flex">
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a target="_blank" :href="`reports/sales`">Reportes de ventas general</a>
      </div>
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a  target="_blank" :href="`reports/sales?frecuency=daily`">Reportes de ventas diario</a>
      </div>
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a target="_blank" :href="`reports/sales?frecuency=weekly`">Reportes de ventas semanal</a>
      </div>
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a target="_blank" :href="`reports/sales?frecuency=monthly`">Reportes de ventas mensual</a>
      </div>
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a target="_blank" :href="`reports/sales?frecuency=year`">Reportes de ventas anual</a>
      </div>
      <div class="bg-indigo-800 text-white m-5 p-2">
        <a target="_blank" :href="`reports/products`">Reportes de productos</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Link,
  },
  layout: Layout,
}
</script>
