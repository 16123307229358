<template>
  <GuestLayout>
    <Head title="Haz aplicado con exito!" />
    <div class="bg-white px-10 py-10">Felicidades, haz aplicado con exito!, en los proximos dias te estaremos contactando para poder procesar tu solicitud.</div>
  </GuestLayout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import GuestLayout from '@/Layouts/GuestLayout.vue'
export default {
  components: {
    Head,
    GuestLayout,
  },
}
</script>
