<template>
  <div>
    <Head title="Informacion de la app" />
    <h1 class="mb-8 text-3xl font-bold">
      Informacion de la App
    </h1>
    <div>
      Esta aplicacion esta realizada en estas tecnologias
      <ul>
        <li>Laravel</li>
        <li>PHP</li>
        <li>VueJs</li>
      </ul>
      Por <a href="https://conexioncreativa.net">Conexion Creativa</a>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout.vue'
import {Head} from '@inertiajs/inertia-vue3'

export default {
  name: 'Information',
  layout: Layout,
  components: {
    Head: Head
  }
}
</script>

<style scoped>

</style>
