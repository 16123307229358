<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Escritorio</h1>
    <p class="text-center text-4xl font-semibold">
      Total de cauchos
    </p>
    <p class="mb-8 text-center text-8xl font-black">
      {{ total.toLocaleString("es") }}
    </p>

    <div
      class="bg-white rounded-md shadow overflow-x-auto"
    >
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Cantidad agregada</th>
          <th class="pb-4 pt-6 px-6">Responsable</th>
        </tr>
        <tr v-for="inventory in inventories" :key="inventory.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDate(inventory.created_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ inventory.quantity }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`inventories/${inventory.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ inventory.user.first_name }} {{ inventory.user.last_name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!inventories">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {Head, Link} from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Link,
    Head,
  },
  layout: Layout,
  props: {
    total: Number,
    inventories: Object,
  },
  methods: {
    getDate(date)
    {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    }
  }
}
</script>
