<template>
  <div>
    <Head title="Ordenes" />
    <div class="flex justify-between py-5">
      <h1 class="mb-8 text-3xl font-bold">Ordenes ({{ total }})</h1>
      <Link :href="'/orders/create'" class="btn-indigo">Crear orden</Link>
    </div>
    

    <div class="bg-white rounded-md shadow overflow-x-auto">
      <div class="flex" style="display: inline-flex;">
        <div v-for="(state, key) in states" :key="key" class="m-5 p-2 bg-gray-400 text-white rounded-xl" style="width: 150px;">
          <a :href="`/orders/?state=${state.state}`">
            {{ getOrderState(state.state) }} {{ state.percent }}%
          </a>
        </div>
      </div>
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Comentario</th>
          <th class="pb-4 pt-6 px-6">Distribuidor</th>
          <th class="pb-4 pt-6 px-6">Vendedor</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders.data" :key="order.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.date }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.comentary }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.dealer.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.responsible ? order.responsible.name : 'Transaccion directa' }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getStateName(order.order_state) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`/orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.total }}
            </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay Ordenes.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="orders.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import {getOrderState, getState} from '@/utils'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
  },
  layout: Layout,
  props: {
    orders: Object,
    states: Object,
    total: Number,
  },
  data() {
    return {
      getStateName(state) {
        return getState(state)
      },
      getOrderState(state) {
        return getOrderState(state);
      }
    }
  }
}
</script>
