<template>
  <div>
    <Head :title="form.name" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/dealers">Distribuidor</Link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ form.name }}
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.name" disabled="true" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
          <text-input v-model="form.lastname" disabled="true" :error="form.errors.lastname" class="pb-8 pr-6 w-full lg:w-1/2" label="Apellido" />
          <text-input v-model="form.identification" disabled="true" :error="form.errors.identification" class="pb-8 pr-6 w-full lg:w-1/2" label="Identificacion" />
          <text-input v-model="form.company" disabled="true" :error="form.errors.company" class="pb-8 pr-6 w-full lg:w-1/2" label="Compañia" />
          <select-input v-model="form.group" :error="form.errors.group" class="pb-8 pr-6 w-full lg:w-1/2" label="Grupo">
            <option value="">Seleccionar</option>
            <option v-for="group in groups" :key="group.id" :value="group.id">
              {{ group.name }}
            </option>
          </select-input>
        </div>
        <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Eliminar distribuidor</button>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Actualizar distribuidor</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  components: {
    Head,
    Icon,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    dealer: Object,
    groups: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.dealer.name,
        lastname: this.dealer.lastname,
        identification: this.dealer.identification,
        company: this.dealer.company,
        group: this.dealer.group,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(`/dealers/${this.dealer.id}`)
    },
    destroy() {
      if (confirm('Estas seguro que quieres eliminar ?')) {
        this.$inertia.delete(`/dealers/${this.dealer.id}`)
      }
    },
    restore() {
      if (confirm('Estas seguro que quieres restaurar estos datos?')) {
        this.$inertia.put(`/dealers/${this.dealer.id}/restore`)
      }
    },
  },
}
</script>
