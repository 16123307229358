<template>
  <div>
    <h1 class="mb-8 text-3xl font-bold">Ordenes</h1>
    <Head title="Crear pedido" />
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-5">
        <div class="flex">
            <div class="w-1/2">
              <text-input v-model="form.client.identification" label="Cedula de Cliente" class="pb-3 pr-6 w-full lg:w-1/2" />
              <button class="btn-indigo" href="/orders/create" @click="findClient">{{ textSearch }} <i class="fa fa-search" /></button>
            </div>
            <div class="w-1/2">
              <div class="bg-blue-300 rounded p-5">
                <input v-model="form.isCredit"  type="checkbox" name="isCredit" id="">
                <label for="" class="ml-2 font-bold text-xl">Crédito</label>
              </div>
            </div>
        </div>
        <!-- Formulario de cliente -->
        <div v-if="showClientCreate" class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.client.name" label="Nombre y Apellido de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.email" label="Email de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.identification" label="Cedula de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.phone" label="Telefono de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.client.address" label="Direccion de Cliente" class="pb-8 pr-6 w-full lg:w-1/2" />
          <text-input v-model="form.comentary" label="Observación" class="pb-8 pr-6 w-full lg:w-1/2" />
        </div>
      </div>
    </div>
    <div class="rounted-md mt-5 bg-white shadow overflow-hidden">
      <div class="p-3">
        <div class="flex items-center justify-between mb-6">
          <button class="btn-indigo" href="/orders/create" @click="listProducts = !listProducts">
            <i class="fa fa-plus" />
          </button>
        </div>
        <div v-if="listProducts">
          <table class="w-full whitespace-nowrap">
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Producto</th>
                <th>Disponibles</th>
                <th>Precio</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, key) in products" :key="key" class="hover:bg-gray-100 focus-within:bg-gray-100" @click="selectProduct(product)">
                <td class="p-2 text-center">{{ product.code }}</td>
                <td class="p-2 text-center">{{ product.name }}</td>
                <td class="p-2 text-center">{{ product.quantity }}</td>
                <td class="p-2 text-center">{{ product.prices[0]['price'] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">#Item</th>
              <th class="pb-4 pt-6 px-6">Codigo</th>
              <th class="pb-4 pt-6 px-6">Producto</th>
              <th class="pb-4 pt-6 px-6">Disponible</th>
              <th>Cantidad</th>
              <th class="pb-4 pt-6 px-6">Precio</th>
              <th class="pb-4 pt-6 px-6">Total</th>
              <td></td>
            </tr>
          </thead>
          <tr v-for="(product, key) in form.products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="p-2 text-center border-t">#{{ key + 1 }}</td>
            <td class="p-2 text-center border-t">
              {{ product.code }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.name }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.stock }}
            </td>
            <td class="p-2 text-center border-t">
              <text-input v-model="form.products[key]['quantity']" :max="product.stock" />
            </td>
            <td class="p-2 text-center border-t">
              <text-input v-model="form.products[key]['price']" :value="product.prices[0]['price']" />
            </td>
            <td class="p-2 text-center border-t">
              {{ (form.products[key]['price'] * product.quantity).toFixed(2) }}
            </td>
            <td class="p-2 text-center border-t">
              <button @click="removeProduct(product)" class="p-2 text-white bg-red-500 rounded-full">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex items-center justify-between mt-6">
      <button 
        @click="generateOrder" 
        class="btn-indigo" 
        href="/orders/create">
          Generar pedido
      </button>
    </div>
  </div>
</template>

<script>
import Layout from '../../Shared/Layout.vue'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'
import { Head } from '@inertiajs/inertia-vue3'

export default {
  components: {
    TextInput,
    Head,
  },
  layout: Layout,
  props: {
    products: Object,
    client: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        mode: 'store',
        client: {
          name: '',
          email: '',
          cedula: '',
          telefono: '',
          address: '',
          identification: '',
        },
        comentary: '',
        products: [],
        method: '',
        referencePay: '',
        transport: {
          type_transport: 2,
          address: 'San diego',
        }
      }),
      listProducts: false,
      showClientCreate: false,
      textSearch: 'Buscar cliente',
    }
  },
  methods: {
    selectProduct(product) {
      if (product.quantity == 0) {
        return alert('No hay stock de este producto')
      }

      let enabled = true // Para saber si ya existe el producto
      this.form.products.forEach((prd) => {
        if (prd.id == product.id) {
          enabled = false
        }
      })
      if (enabled) {
        this.form.products.push({ ...product, stock: product.quantity, quantity: 1, price: product.prices[0]['price'] })
      }
    },
    removeProduct(product) {
      if (confirm('Estas seguro que quieres eliminar este item?')) {
        let products = this.form.products
        let newProducts = products.filter((prd) => prd.id != product.id)
        this.form.products = newProducts
      }
    },
    findClient() {
      this.$inertia.get('/orders/create', pickBy(this.form), { preserveState: true })
      this.textSearch = 'Buscando...'

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form = this.$inertia.form({
            mode: 'store',
            client: {
              name: this.client.name,
              email: this.client.email,
              phone: this.client.phone,
              address: this.client.address,
              identification: this.client.identification
            },
            comentary: '',
            products: [],
            method: '',
            referencePay: '',
            transport: {
              type_transport: 2,
              address: 'San diego',
            }
          })
        } else {
          this.textSearch = 'Buscar cliente'
          alert('No se encontro cliente, puede crearlo en el siguiente formulario')
          this.showClientCreate = true
        }
      }, 3000)
    },
    generateOrder() {
      this.form.post('/orders')
    },
  },
}
</script>
