<script>
import { Head } from '@inertiajs/inertia-vue3'
import GuestLayout from '@/Layouts/GuestLayout.vue'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import Logo from '@/Shared/Logo'
import SelectInput from '@/Shared/SelectInput.vue'
import FlashMessages from '@/Shared/FlashMessages'

export default {
  components: {
    Head,
    Logo,
    GuestLayout,
    TextInput,
    LoadingButton,
    SelectInput,
    FlashMessages
  },
  data() {
    return {
      form: this.$inertia.form({
        name: '',
        lastname: '',
        idType:'V',
        identification: '',
        company: '',
        state: '',
        town: '',
        address: '',
        email: '',
        phone: '',
      }),
      states: [],
      towns: [],
      idType:'V',
    }
  },
  watch: {
    'form.state': function () {
      this.getTowns()
    },
    'form.identification': function (val) {
      if(val.length > 12)
      {
        this.form.identification = ""
        alert('No puede ser mayor a de 12')
      }

    }
  },
  mounted() {
    this.getStates()
  },
  methods: {
    submit(){
      this.form.post('/dealers/apply')
    },
    getStates() {
      fetch('/utils/states')
        .then(response => response.json())
        .then(json => this.states = json.data)
        .catch(err => console.log(err))
    },
    getTowns() {
      fetch(`/utils/towns/${this.form.state}`)
        .then(response => response.json())
        .then(json => this.towns = json.data)
        .catch(err => console.log(err))
    },
  },
}
</script>
<template>
  <GuestLayout>
    <flash-messages />
    <Head title="Aplicar para distribuidor" />
    <logo class="block mx-auto w-full max-w-xs fill-white" height="50" />
    <form class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="submit">
      <div class="px-10 py-12">
        <div>
          <label for="name">Nombres</label>
          <text-input id="name" v-model="form.name" type="text" class="block mt-1 w-full" required autofocus autocomplete="name" />
        </div>
        <div class="mt-2">
          <label for="lastname">Apellidos</label>
          <text-input id="lastname" v-model="form.lastname" type="text" class="block mt-1 w-full" required autofocus autocomplete="lastname" />
        </div>
        <div class="mt-2">
          <label for="identification">Cedula / RIF</label>
          <div class="flex">
            <select-input v-model="form.idType" class="mt-1" style="width: 70px;">
              <option value="V">V</option>
              <option value="E">E</option>
              <option value="J">J</option>
              <option value="G">G</option>
            </select-input>
            <TextInput id="identification" v-model="form.identification" type="number" class="mt-1 w-full" required autofocus autocomplete="identification" />
          </div>
        </div>
        <div class="mt-2">
          <label for="company">Razon social</label>
          <text-input id="company" v-model="form.company" type="text" class="block mt-1 w-full" required autofocus autocomplete="company" />
        </div>
        <div class="mt-2">
          <label for="state">Estado</label>
          <select-input
            id="state"
            v-model="form.state"
            class="block mt-1 w-full"
            required
            autofocus
            autocomplete="state"
          >
            <option value="">Seleccionar</option>
            <option v-for="(state, key) in states" :key="key" :value="state.id">
              {{ state.name }}
            </option>
          </select-input>
        </div>
        <div class="mt-2">
          <label for="town">Municipio</label>
          <select-input
            id="town"
            v-model="form.town"
            class="block mt-1 w-full"
            required
            autofocus
            autocomplete="town"
          >
            <option value="">Seleccionar</option>
            <option v-for="(town, key) in towns" :key="key" :value="town.id">
              {{ town.name }}
            </option>
          </select-input>
        </div>
        <div class="mt-2">
          <label for="address">Direccion</label>
          <text-input id="address" v-model="form.address" type="text" class="block mt-1 w-full" required autofocus autocomplete="address" />
        </div>
        <div class="mt-2">
          <label for="email">Correo electronico</label>
          <text-input id="email" v-model="form.email" type="email" class="block mt-1 w-full" required autofocus autocomplete="email" />
        </div>
        <div class="mt-2">
          <text-input id="phone" v-model="form.phone" label="Telefono" type="number" class="block mt-1 w-full" required autofocus autocomplete="phone" />
        </div>
        <div class="flex items-center justify-end mt-4">
          <loading-button class="btn-indigo ml-auto" :disabled="form.processing" type="submit"> Aplicar </loading-button>
        </div>
      </div>
    </form>
  </GuestLayout>
</template>
