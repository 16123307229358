<template>
  <div>
    <Head title="Pedido" />
    <h1 class="mb-8 text-3xl font-bold">
      Pedido #{{ order.id }}
      <a target="_blank" :href="`/orders/${order.id}/report`" class="p-2 bg-indigo-800 rounded">
        <i class="fa fa-print text-white" />
      </a>
    </h1>
    <div class="flex justify-between mb-5 p-5 bg-white rounded-md shadow overflow-x-auto">
      <div>
        <div class="pb-2">Cliente: {{ order.dealer.name }}</div>
        <br />
        <div class="pb-2">Cedula: {{ order.dealer.identification }}</div>
        <br />
        <div class="pb-2">Email: {{ order.dealer.email }}</div>
        <br />
        <div class="pb-2">Telefono: {{ order.dealer.phone }}</div>
        <br />
        <div class="pb-2">Balance: {{ order.balance }}$</div>
        <br />
        <div class="pb-2">Total: {{ order.total }}$</div>
        <br />
        <div class="pb-2">Estado: {{ order.order_state }}</div>
      </div>
    </div>
    <div class="bg-white rounded-md shadow">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr>
            <th class="pb-4 pt-6 px-6">#Item</th>
            <th class="pb-4 pt-6 px-6">Producto</th>
            <th class="pb-4 pt-6 px-6">Descripcion</th>
            <th>Cantidad</th>
            <th class="pb-4 pt-6 px-6">Precio</th>
            <th class="pb-4 pt-6 px-6">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, key) in order.products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
            <td class="p-2 text-center border-t">#{{ key + 1 }}</td>
            <td class="p-2 text-center border-t">
              {{ product.product.name }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.product.description }}
            </td>
            <td class="p-2 text-center border-t">
              {{ product.quantity }}
            </td>
            <td class="p-2 text-center border-t">${{ product.price }}</td>
            <td class="p-2 text-center border-t">${{ ( product.price * product.quantity).toFixed(2) }}</td>
          </tr>
        </tbody>
        <tfoot class="px-6 py-2" style="background-color: #f3f3f3">
          <tr>
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t"><strong>Descuento</strong></td>
            <td class="p-2 text-center border-t">${{ (discount).toFixed(2) }}</td>
          </tr>
          <tr>
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t" />
            <td class="p-2 text-center border-t"><strong>Total</strong></td>
            <td class="p-2 text-center border-t">${{ order.total }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    
    <!-- Formulario de pagos -->
    <div  class="bg-white shadow p-5 mt-5">
      <h2 class="mb-8 text-xl font-bold">Pagos</h2>
      <div v-if="role == 'financial' && order.order_state == 'pay'" class="pt-2">
        <div class="flex-1">
          <div class="pb-8 pr-6 w-full">
            <label for="">Metodo de pago</label>
            <select v-model="form.method" class="form-input mt-2 w-full">
              <option value="">Seleccionar</option>
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia Bs</option>
              <option value="transferencia-usd">Transferencia Divisas</option>
              <option value="zelle">Zelle</option>
              <option value="credito">Credito</option>
            </select>
          </div>
          <text-input label="Monto" v-model="form.amount" type="number" class="pb-8 pr-6 w-full"  />
          <text-input
            v-if="form.method === 'transferencia' || form.method === 'transferencia-usd' || form.method === 'zelle'"
            v-model="form.reference"
            :error="form.errors.reference"
            class="pb-8 pr-6 w-full"
            label="Referencia"
          />
        </div>

        <button class="p-2 rounded bg-indigo-800 text-white text-md my-2" @click="addPay">
          Agregar pagos
        </button>
      </div>
      <div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
              <th class="pb-4 pt-6 px-6">Metodo</th>
              <th class="pb-4 pt-6 px-6">Monto</th>
              <th class="pb-4 pt-6 px-6">Fecha</th>
              <th class="pb-4 pt-6 px-6">Referencia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pay in order.pays" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="border-t text-center p-2 capitalize">
                {{ pay.method }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.amount }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.created_at }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.reference }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '../../Shared/TextInput.vue'

export default {
  components: {
    Head,
    Link,
    TextInput,
  },
  layout: Layout,
  props: {
    order: Object,
  },
  data() {
    return {
      role: this.$parent.auth.user.role,
      form: this.$inertia.form({
        amount: '',
        method: '',
        order_id: this.order?.id
      }),
      total: 0,
      discount: 0,
      getPrice(prices, dealer) {
        let priceResult = 0
        prices.forEach(price => {
          if(price.group_id == dealer.group)
          {
            priceResult = price.price
          }
        })
        this.price = priceResult
        return priceResult
      },
    }
  },
  mounted() {
    this.defineTotals()
  },
  methods: {
    addPay()
    {
      console.log(this.order.total);
      console.log((parseInt(this.form.amount) + parseInt(this.order.balance)))
      if(this.order.total < (parseInt(this.form.amount) + parseInt(this.order.balance)))
      {
        return alert("No se puede agregar este pago si el monto es mayor al balance");
      }
      this.form.order_id  = this.order.id
      this.form.post('/orders/pay')

      this.cleanPay()

    },
    defineTotals() {
      let total = 0
      this.order.products.forEach(orderProduct => {
        let productPrice = () => {
          let prices = orderProduct.product.prices
          for (const price of prices) {
            if(price.group_id == this.order.dealer.group)
            {
              return price.price
            }
          }
          return 0
        }
        console.log(productPrice())
        console.log(orderProduct.quantity)
        total += productPrice() * orderProduct.quantity
      })
      this.total = total
      this.discount = total ? total - this.order.total : 0
    },
  },
}
</script>
